import styled, { css } from 'styled-components'
import {
  colors,
  fonts,
  media,
  textMd,
  textSm,
  textLg,
} from '../../../../lib/constants'

export const ProjectWrap = styled.div`
  width: 47.268%;
  margin: 2.5rem auto 6.25rem;

  ${media.tablet`
    width: 100%;
    margin-top: 1rem;
  `}
`

export const Project = styled.div`
  display: none;
  opacity: 0;
  transition: opacity 0.6s;

  > a {
    display: none;
  }

  ${(props) =>
    props.active &&
    css`
      display: block;
      opacity: 1;
    `}

  ${media.tablet`
    text-align: center;

    > a {
      display: inline-block;
      margin-top: 1.25rem;
    }
  `}
`

export const ProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    justify-content: center;
    text-align: center;

    a {
      display: none;
    }
  `}
`

export const ProjectTitle = styled.h3`
  font-family: ${fonts.headings};
  ${textLg}
  text-transform: uppercase;
  font-weight: 700;
  color: ${colors.blue};
  margin-bottom: 0;

  ${media.wide`
    font-size: 2rem;
  `}

  ${media.desktop`
    font-size: 1.875rem;
  `}

  ${media.tablet`
    font-size: 2rem;
  `}
`

export const ProjectClient = styled.span`
  font-family: ${fonts.headings};
  font-size: 0.75rem;
  font-weight: 400;
`

export const ProjectDescription = styled.div`
  padding-top: 50px;

  ${media.tablet`
    padding-top: 20px;
    text-align: center;
  `}
`

export const CarouselItem = styled.div`
  background-size: 100%;
  background-repeat: no-repeat;

  img {
    opacity: 0;
    width: 100%;
  }

  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
    `}
`

export const CarouselInfo = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: -100px;
  left: 0;
  color: ${colors.blue};
  transform: scale(1.32);

  ${media.desktop`
    bottom: -70px;
  `}

  ${media.tablet`
    display: none;
    transform: scale(1);
  `}
`

export const CarouselInfoTitle = styled.h3`
  ${textMd}
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;

  ${media.desktop`
    margin-bottom: 0;
  `}
`

export const CarouselInfoSubTitle = styled.div`
  ${textSm}
  font-family: ${fonts.headings};
  font-weight: 400;
  color: ${colors.blue};
`

export const ProjectsCarousel = styled.div`
  margin: 0 -70px;

  .slide {
    padding: 0 10px;
    transition: all 0.6s;
    position: relative;
    transform: scale(0.67);

    &.selected {
      transform: scale(1.33);

      ${CarouselInfo} {
        opacity: 0;
      }
    }
  }

  .carousel.carousel-slider {
    overflow: visible;
  }

  .control-dots {
    bottom: -11rem !important;
    left: 0;
    justify-content: center;
    display: flex;
    height: 2rem;
    cursor: pointer;
  }

  ${media.desktop`
    margin: 0 -50px;
  `}

  ${media.tablet`
    margin: 0 -10px;
    padding-top: 0;

    .carousel.carousel-slider {
      overflow: visible;
    }

    .control-dots {
      display: flex;
      bottom: -14rem !important;
    }

    .slide {
      padding: 0 5px;
      transform: scale(1);
  
      &.selected {
        transform: scale(1);
      }
    }
  `}
`

export const Indicator = styled.div`
  font-family: ${fonts.headings};
  font-size: 1.3888rem;
  color: ${colors.grey__light};
  margin-right: 1.625rem;

  ${(props) =>
    props.active &&
    css`
      color: ${colors.blue__dark};
    `}
`
