import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import Button from '../../Global/Button'
import BtnIcon from '../../Global/Icon/BtnIcon'
import ArrowRight from '../../Global/Icon/ArrowRight'
import * as S from './Projects.css'
import useWindowSize from '../../../hooks/useWindowSize'

const Project = ({ title, link, active }) => {
  return (
    <S.Project active={active}>
      <S.ProjectInfo>
        <div>
          <S.ProjectTitle>{title}</S.ProjectTitle>
        </div>
        <Button type="link" href={link} style={{ whiteSpace: 'nowrap' }}>
          Check it out
        </Button>
      </S.ProjectInfo>
      <Button type="link" href={link} style={{ whiteSpace: 'nowrap' }}>
        Check it out
      </Button>
    </S.Project>
  )
}

const Projects = ({ projects }) => {
  const [isInit, setIsInit] = useState(false)
  const [key, setKey] = useState(1)
  const windowSize = useWindowSize()
  const [settings, setSettings] = useState({
    infiniteLoop: true,
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    centerMode: true,
    showIndicators: true,
    selectedItem: 1,
    autoPlay: true,
    transitionTime: 600,
    interval: 5000,
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance: 30,
    onClickItem: (index) => {
      setKey(index)
    },
    onChange: (index) => {
      setKey(index)
    },
    renderIndicator: (onClickHandler, isSelected, index) => {
      if (isSelected) {
        return <S.Indicator active>{`0${index + 1} __`}</S.Indicator>
      }
      return (
        <S.Indicator
          onClick={() => {
            onClickHandler()
            setKey(index)
          }}
          onKeyDown={() => {
            onClickHandler()
            setKey(index)
          }}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
        >
          {`0${index + 1}`}
        </S.Indicator>
      )
    },
  })

  useEffect(() => {
    const isMobile = windowSize.width < 768

    if (windowSize.width) {
      setIsInit(true)
    }

    setSettings({
      ...settings,
      centerSlidePercentage: isMobile ? 100 : 100 / 3,
    })
  }, [isInit, windowSize.width])

  return (
    <>
      {isInit && (
        <S.ProjectsCarousel>
          <Carousel {...settings} selectedItem={key}>
            {projects.map(({ project }, idx) => (
              <S.CarouselItem
                image={project.frontmatter.home_image?.publicURL}
                key={`project-carousel-${idx}`}
              >
                <img src={project.frontmatter.home_image?.publicURL} alt="" />
                <S.CarouselInfo>
                  <S.CarouselInfoTitle>
                    {project.frontmatter.project_title}
                  </S.CarouselInfoTitle>
                </S.CarouselInfo>
              </S.CarouselItem>
            ))}
          </Carousel>
        </S.ProjectsCarousel>
      )}

      <S.ProjectWrap>
        {projects.map(({ project }, index) => (
          <Project
            key={`project-details-${index}`}
            active={key === index}
            title={project.frontmatter.project_title}
            client={project.frontmatter.client}
            link={project.fields.slug}
            description={project.frontmatter.description}
          />
        ))}
      </S.ProjectWrap>

      <div className="text-center">
        <Button type="link" transparent href="/projects/">
          More projects &nbsp;
          <BtnIcon>
            <ArrowRight />
          </BtnIcon>
        </Button>
      </div>
    </>
  )
}

export default Projects
