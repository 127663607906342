import React from 'react'
import htmr from 'htmr'
import { Row, Col } from '../../../layouts/grid.css'
import Container from '../../../layouts/container'
import Video from '../../Global/Video'
import * as S from './MainContent.css'

const MainContent = ({
  description,
  content,
  icon,
  image_01,
  image_02,
  video,
}) => {
  return (
    <S.Wrapper>
      <S.Head>
        <Row gutter={10}>
          <Col md={6}></Col>
          <Col md={6} style={{ lineHeight: 0 }}>
            <S.Image src={icon} alt="" />
          </Col>
        </Row>
        <S.InfoInner>
          <Container>
            <Row gutter={10}>
              <Col md={6}>{description}</Col>
              <Col md={6}></Col>
            </Row>
          </Container>
        </S.InfoInner>
      </S.Head>

      <Container padding="8rem 0 4rem">
        <Row gutter={60} itemsCenter>
          <Col lg={6}>
            <Row gutter={10} style={{ flexWrap: 'unset' }}>
              <Col xs={8} sm={8} style={{ lineHeight: 0 }}>
                {image_01 && <S.Image rounded src={image_01} alt="" />}
              </Col>
              <Col xs={4} sm={4} style={{ lineHeight: 0 }}>
                {image_02 && <S.Image rounded src={image_02} alt="" />}
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={10} sm={10}>
                <Video
                  style={{ marginTop: 20 }}
                  thumbnail={video?.thumbnail?.publicURL}
                  fullWidth
                  rounded
                  url={video?.url}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <S.Text>{content && htmr(content)}</S.Text>
          </Col>
        </Row>
      </Container>
    </S.Wrapper>
  )
}

export default MainContent
