import styled, { css } from 'styled-components'
import { textSm, media } from '../../../../lib/constants'

export const Wrapper = styled.div`
  iframe {
    margin-bottom: 0;
  }
`

export const Head = styled.div`
  margin-bottom: 0;
  position: relative;
  margin-top: -370px;

  ${media.wide`
    margin-top: -260px;
  `}

  ${media.tablet`
    margin-top: -125px;
  `}
`

export const Image = styled.img`
  margin-bottom: 0;
  width: 100%;

  ${props =>
    props.rounded &&
    css`
      border-radius: 30px;
    `}
`

export const Text = styled.div`
  ${textSm}

  p:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    margin-top: 3.75rem;
  `}
`

export const InfoInner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    display: flex;
    height: 100%;

    > div {
      display: flex;
      align-items: flex-end;
      padding-bottom: 0;
    }
  }

  ${media.tablet`
    position: relative;
    margin-bottom: 1rem;
  `}
`
