import styled, { css } from 'styled-components'
import { colors, fonts, media } from '../../../../lib/constants'
import Heading from '../../Global/Heading'

export const CarouselWrap = styled.div`
  width: 100%;
  height: 100%;

  .slide {
    padding: 0 10px;
  }

  .control-dots {
    top: auto;
    left: 0;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .carousel.carousel-slider {
    margin-left: 9.03%;

    &:before {
      position: absolute;
      top: 0;
      right: 100%;
      width: 100%;
      height: 100%;
      background-color: ${colors.white};
      content: ' ';
      display: block;
      z-index: 9;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 200%;
      width: 100%;
      height: 100%;
      background-color: ${colors.white};
      content: ' ';
      display: block;
      z-index: 9;
    }

    ${media.tablet`
      margin-left: 0;
    `}
  }

  .carousel .slider-wrapper,
  .carousel.carousel-slider {
    overflow: inherit;

    ${media.tablet`
      overflow: hidden;
    `}
  }

  ${media.desktop`
    position: relative;
  `}
`

export const Item = styled.div`
  text-align: left;
  padding: 40px 30px;
  background-color: ${colors.blue};
  color: ${colors.white};
  border-radius: 20px;

  ${media.tablet`
    padding: 2rem 1rem;
  `}
`

export const ItemLogo = styled.img`
  width: 85px !important;
  margin-bottom: 30px;
`

export const ItemContent = styled.div`
  margin-bottom: 70px;
`

export const ItemInfo = styled.div`
  position: relative;
  padding-top: 1rem;

  &::after {
    position: absolute;
    height: 5px;
    width: 100px;
    content: ' ';
    left: 0;
    top: 0;
    background-color: ${colors.pink};
  }
`

export const ItemInfoTitle = styled.span`
  font-size: ${fonts.headings};
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
`

export const ItemInfoName = styled(Heading)`
  color: ${colors.white};
  margin-bottom: 0.2rem;
`

const controlStyle = css`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    display: none;
  `}
`

export const PrevButton = styled.div`
  ${controlStyle}
  left: -88px;
`

export const NextButton = styled.div`
  ${controlStyle}
  right: calc(-100% - 88px);
`

export const Indicator = styled.div`
  font-family: ${fonts.headings};
  font-size: 1.3888rem;
  color: ${colors.grey__light};
  margin-right: 1.625rem;

  ${props =>
    props.active &&
    css`
      color: ${colors.blue__dark};
    `}
`
