import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import useWindowSize from '../../../hooks/useWindowSize'
import * as S from './OurSuccess.css'

const OurSuccess = ({ testimonials }) => {
  const windowSize = useWindowSize()
  const [settings, setSettings] = useState({
    infiniteLoop: true,
    showArrows: true,
    showStatus: false,
    showThumbs: false,
    interval: 6000,
    transitionTime: 600,
    autoPlay: false,
    renderIndicator: (onClickHandler, isSelected, index) => {
      if (isSelected) {
        return <S.Indicator active>{`0${index + 1} __`}</S.Indicator>
      }
      return (
        <S.Indicator
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
        >
          {`0${index + 1}`}
        </S.Indicator>
      )
    },
    renderArrowPrev: (onClickHandler) => (
      <S.PrevButton onClick={onClickHandler}>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.82823 4.99996L5.65723 2.17196L4.24323 0.756958L0.000226974 4.99996L4.24323 9.24296L5.65723 7.82796L2.82823 4.99996Z"
            fill="white"
          />
        </svg>
      </S.PrevButton>
    ),
    renderArrowNext: (onClickHandler) => (
      <S.NextButton onClick={onClickHandler}>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.17177 5.00004L0.342773 7.82804L1.75677 9.24304L5.99977 5.00004L1.75677 0.757042L0.342773 2.17204L3.17177 5.00004Z"
            fill="white"
          />
        </svg>
      </S.NextButton>
    ),
  })

  useEffect(() => {
    const isMobile = windowSize.width < 768

    setSettings({
      ...settings,
      width: isMobile ? '100%' : '40.97%',
      showIndicators: isMobile ? true : false,
    })
  }, [windowSize.width])

  return (
    <S.CarouselWrap>
      <Carousel {...settings}>
        {testimonials.map((it, key) => (
          <S.Item key={key}>
            <S.ItemLogo src={it.client_logo.publicURL} alt={it.client_name} />
            <S.ItemContent>{it.content}</S.ItemContent>
            <S.ItemInfo>
              <S.ItemInfoName level={4}>{it.client_name}</S.ItemInfoName>
              <S.ItemInfoTitle>{it.title}</S.ItemInfoTitle>
            </S.ItemInfo>
          </S.Item>
        ))}
      </Carousel>
    </S.CarouselWrap>
  )
}

export default OurSuccess
