import React from 'react'
import { graphql } from 'gatsby'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import Hero from '../components/Global/Hero'
import Article from '../components/Global/Article'
import Heading from '../components/Global/Heading'
import CTA from '../components/Global/CTA'
import MainContent from '../components/Services/MainContent'
import OurSuccess from '../components/Services/OurSuccess'
import Projects from '../components/Home/Projects'
import { Row, Col } from '../layouts/grid.css'
import { colors } from '../../lib/constants'

const ServicesPage = ({ data }) => {
  const {
    icon,
    image_01,
    image_02,
    video,
    title,
    description,
    testimonials,
    featured_projects,
    cta,
  } = data.markdownRemark.frontmatter
  const articles = data.posts.edges

  return (
    <Layout>
      <Helmet>
        <title>{title} - Services - TechEquipt</title>
        <meta name="description" content={description} />
      </Helmet>

      <Hero title={title} size="medium" backgroundText />

      <MainContent
        description={description}
        content={data.markdownRemark.html}
        icon={icon.publicURL}
        image_01={image_01 && image_01.publicURL}
        image_02={image_02 && image_02.publicURL}
        video={video}
      />

      {featured_projects?.projects?.length > 0 && (
        <Container padding="8rem 0 4rem">
          <Heading
            level={3}
            hasLine
            center
            lineWidth={80}
            lineSize={5}
            style={{ marginBottom: 50 }}
          >
            {featured_projects?.title}
          </Heading>
          <Projects projects={featured_projects.projects} />
        </Container>
      )}

      {testimonials?.length > 0 && (
        <Container padding="8rem 0 4rem">
          <Heading
            level={3}
            hasLine
            center
            lineWidth={80}
            lineSize={5}
            style={{ marginBottom: 50 }}
          >
            Our success
          </Heading>

          <OurSuccess testimonials={testimonials} />
        </Container>
      )}

      {articles?.length > 0 && (
        <Container>
          <Heading
            level={3}
            hasLine
            center
            lineWidth={80}
            lineSize={5}
            style={{ marginBottom: 50 }}
          >
            Our thoughts
          </Heading>
          <Article
            {...articles[0].node.frontmatter}
            slug={articles[0].node.frontmatter.slug}
            featured
          />
          <Row gutter={10}>
            {articles.map(
              (article, key) =>
                key > 0 && (
                  <Col key={key} md={4}>
                    <Article
                      {...article.node.frontmatter}
                      slug={article.node.frontmatter.slug}
                    />
                  </Col>
                )
            )}
          </Row>
        </Container>
      )}
      {cta?.title && cta?.image?.publicURL && (
        <Container color={colors.white}>
          <CTA
            bgColor="#F7F6F6"
            heading={cta?.title}
            content={cta?.content}
            btnText={cta?.button_text}
            btnLink={cta?.button_link}
            image={cta?.image?.publicURL}
          />
        </Container>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        slug
        title
        description
        icon {
          publicURL
        }
        image_01 {
          publicURL
        }
        image_02 {
          publicURL
        }
        video {
          url
          thumbnail {
            publicURL
          }
        }
        featured_projects {
          title
          projects {
            project {
              fields {
                slug
              }
              frontmatter {
                project_title
                feature_image {
                  publicURL
                }
                home_image {
                  publicURL
                }
                description
                client
              }
            }
          }
        }
        testimonials {
          client_name
          title
          content
          client_logo {
            publicURL
          }
        }
        cta {
          title
          content
          image {
            publicURL
          }
          button_text
          button_link
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: 4
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            hero_image {
              publicURL
            }
            templateKey
            date(formatString: "MMMM DD YYYY")
            tags
            description
            author
          }
        }
      }
    }
  }
`

export default ServicesPage
